#sidebar {
  position: fixed;
  text-align: center;
  min-width: 20%;
  margin-left: 2%;
}

.each-category {
  border-radius: 5px;
  background: #000;
  color: #fff;
  margin: 2px 0;
  padding: 6px 0;
  cursor: pointer;
}

#selected {
  background: #fff;
  color: #000;
}
