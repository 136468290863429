.AllCategoryVideos {
  /* margin : 0 50px; */
  background: black;
}
.main-video-container {
  /* margin-top: 1.5%; */
  margin: 0 50px;
}
.main-video-container #cat-img-name {
  text-align: left;
  padding: 0% 0 2% 2%;
}
#cat-img-name {
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding: 2% 0 0 2%;
  margin: 0;
}
.catImgCard {
  height: 55vh;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  margin-bottom: 20px;
}
.catImgCard::after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
#cat-name {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 2.2rem;
}
.catImgCard:hover:after {
  opacity: 0.7;
}
.all-video-container {
  /* padding: 0 5px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.videog {
  margin-bottom: 2%;
}
@media only screen and (max-width: 1473px) {
  .main-video-container {
    margin: 0 5px;
  }
}
@media only screen and (max-width: 413px) {
  .main-video-container {
    margin: 0;
  }
}
