.img_thumb {
  object-fit: cover;
}

small {
  display: block;
}

.card {
  cursor: pointer;
}

.card-p {
  margin: 0;
  padding: 0;
}

.card-body {
  padding: 0 8px;
}

.icons:hover {
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
}

.r-title {
  margin-top: 12px;
}

.r-data {
  font-size: 14px;
  margin-bottom: 10px;
}

a {
  margin: 0 auto 10px auto;
  font-size: 10px !important;
}
.dropdown-record {
  left: -129px !important;
}