.firebaseui-container.mdl-card {
  /* background-color: transparent !important; */
  /* border: 1px solid white; */
}
.firebaseui-card-header h1 {
  color: black !important;
}
.firebaseui-card-header {
  /* background-color: black !important; */
}
.firebaseui-country-selector {
  /* color: white !important; */
}
.firebaseui-card-actions button {
  width: auto !important;
}
.mdl-textfield--floating-label .mdl-textfield__label {
  /* color: white; */
  margin: 0px !important;
  font-size: 1rem;
  padding-left: 2px;
}
.firebaseui-card-content {
  padding-top: 15px !important;
}
.firebaseui-text {
  /* color: white !important; */
}
.firebaseui-id-resend-countdown {
  /* color: white !important; */
}

/* Phone Input */
.react-tel-input .form-control {
  width: 100%;
  height: 56px;
  background-color: transparent;
  color: black;
}
