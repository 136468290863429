#category-more-button {
  padding-right: 15px;
  float: right;
  font-size: 1rem !important;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
#category-more-button:hover {
  color: rgb(204, 193, 177);
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.flickity-page-dots {
  z-index: 1;
  bottom: 15px;
}
.flickity-page-dots .dot {
  background: #fff;
  opacity: 1;
}
.flickity-page-dots .dot.is-selected{
   background: rgb(255, 11, 10);
}
.flickity-button {
  background: transparent;
}
.flickity-button:hover {
  background: rgba(255, 11, 10,0.5);
}
.carousel .img {
  /* z-index: 1; */
  width: 60vw;
  margin: 0 15px;
}
.carousel img {
  width: 100%;
}
/* .carousel .is-selected {
  box-shadow: 0px 0px 10px 0px #ebe2e2;
} */
.carousel-title {
  z-index: 1;
  position: absolute;
  width: 100%;
  bottom: 40px;
  text-align: center;
  font-weight: 900;
  font-size: 1.4rem;
  /* background-color: black; */
  color: white;
}

@media only screen and (max-width: 522px) {
  .carousel-title {
    bottom: 20px;
    font-size: 1em;
  }
  .flickity-page-dots {
    bottom: 2px;
  }
  .flickity-prev-next-button {
    height: 30px;
    width: 30px;
  }
}
