.website-title a {
  font-family: "Work Sans, sans-serif";
  font-weight: 600;
  text-align: "left";
  /* margin: 0; */
  color: white;
  font-size: x-large !important;
}
.website-title a:hover {
  color: rgb(223, 211, 211);
  text-decoration: none;
}
.menuButton {
  font-family: "Open Sans, sans-serif";
  font-weight: 700 !important;
  font-size: inherit !important;
  margin-left: "38px" !important;
}
.menuButton a {
  font-size: "200px" !important;
}
.menuButton:hover {
  color: #fff;
}
.drawerButton {
  text-transform: none;
}
/* category padding */
.MuiInputBase-input {
  padding-top: 3px !important;
}
/* Category Underline Hide */
.MuiInput-underline:before {
  display: none;
}
.MuiInput-underline:after {
  display: none;
}
.MuiMenu-paper {
  top: 50px !important;
}
.MuiSelect-icon {
  color: white !important;
}
/* Category Font */
.MuiMenuItem-root {
  font-size: 1rem !important;
}
/* Search Container */
.search-container {
  display: inline-flex;
  height: 40px;
  border-radius: 40px;
  position: relative;
  background: black;
}
#search-text {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-weight: 700;
  letter-spacing: 0.1rem;
  font-size: 1rem;
  transition: all 0.5s;
  line-height: 40px;
  width: 0px;
}
.search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  float: right;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.5s;
  cursor: pointer;
  background: #000;
}
#search-text::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

#search-text:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

#search-text::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}
.search-container:focus-within {
  padding-left: 10px;
  box-shadow: 0px 0px 5px 0px #fff;
}
.search-container:focus-within > #search-text input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.search-container:focus-within > #search-text {
  width: 240px;
  /* border-bottom: 2px solid #fff; */
  padding: 0 6px;
}

.search-container:focus-within > .search-btn {
  background: #fff;
  color: #000;
}
/* search result */
.search-result-container {
  display: none;
  position: absolute;
  width: 260px;
  top: 50px;
  overflow-y: scroll;
  max-height: 210px;
  padding: 3px;
  background-color: #000;
}
.search-container:focus-within .search-result-container {
  display: block;
}
.search-result {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  height: 80px;
  background-color: #fff;
}
.search-result .left {
  width: 40%;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.search-result .left img {
  width: 100%;
  height: 100%;
}
.search-result .right {
  width: 60%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3px;
  color: #000;
  justify-content: space-between;
}
.search-result .right .upper p {
  margin: 0;
  padding: 0;
}
.search-result .right .bottom {
  display: flex;
  justify-content: space-between;
}
#search-data-link:hover{
text-decoration: none;
}
/* Video making buttons */
.navvidbtn {
  text-decoration: none !important;
  color: #fff;
  font-size: 1rem !important;
  margin-left: 10px;
}
.navvidbtn:hover {
  color: antiquewhite;
}
@media only screen and (max-width: 900px) {
  .MuiMenu-paper {
    top: 125px !important;
  }
}
