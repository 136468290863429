html::-webkit-scrollbar {
  display: none;
  -webkit-overflow-scrolling: touch;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* width: auto; */
  font-family: "Nunito", sans-serif;
}
.loginForm {
  padding: 20px 20px 10px 20px;
  max-width: 502px;
  margin: 30px auto;
}
.loginForm a {
  text-decoration: none;
  font-weight: 700;
  color: rgb(230, 86, 19);
  font-size: 1rem !important;
}
.loginGrid {
  margin: 10px;
}
.loginField {
  /* margin-top: 20px; */
}
/* button {
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #603bbb;
  cursor: pointer;
  border-radius: 5px;
} */
/* button:hover {
  background-color: #38bb4a !important;
} */

.login {
  width: 100%;
  min-height: 100vh;
  min-width: 520px;
  padding: 0 20px;
  background: #e9e9e9;
  display: flex;
}

.login .loginContainer {
  padding: 60px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  ); */
  box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.login .loginContainer label {
  color: white;
  margin: 14px 0;
  display: block;
  font-size: 22px;
  line-height: 1;
}

.login .loginContainer input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px 40px 10px 10px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  letter-spacing: 1px;
}

.login .loginContainer h1 {
  text-align: center;
  color: #fff;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 24px 0;
}

.login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: right;
  color: #fff;
}

.login .loginContainer .btnContainer p span {
  color: yellow;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

/* .login .loginContainer .btnContainer p span:hover {
  color: red;
} */

.login .loginContainer .errorMsg {
  margin-top: 15px;
  color: red;
  font-size: 16px;
}

.home {
  width: 100%;
  min-height: 100vh;
  /* background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  ); */
  background: black;
}

.navbar {
  padding: 0;
  position: fixed;
  width: 100vw;
  z-index: 22;
  color: #e9e9e9;
  margin-bottom: 250px;
}
.navbar a {
  position: absolute;
  left: 8%;
  color: #fff;
  font-size: 1.5rem !important;
}
.navbar a:hover {
  text-decoration: none;
  color: #c04848;
}
.navbar nav {
  background: #603bbb;
  width: 100%;
  padding: 8px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar nav button {
  width: 86px;
  padding: 8px 0;
  background: #3a2b58;
}
/* Profile picture */
#user-name {
  position: relative;
  left: 60%;
}
/* #user-name div .name {
  border: 1px solid black;
} */
#user-avatar {
  position: relative;
  left: 15%;
}
#avatar {
  margin-top: -10px !important;
  height: 40px;
  width: 50px;
  border-radius: 50%;
}
/* .login a {
  font-size: 1.1rem !important;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
}
.login a:hover {
  color: red;
} */
/* .PhoneInput {
  color: rgb(255, 255, 255);
  padding-left: 10px;
  background: rgba(255, 255, 255, 0.1);
}
.login #showpassword {
  display: flex;
  color: #fff;
  width: 0px;
  margin-top: -30px;
  left: 92%;
  position: relative;
  z-index: 2;
} */

.video-model {
  height: 50vh;
  width: 50vh;
}

/* home category name */
.category-name {
  margin-left: 10px;
  color: white;
  user-select: none;
  font-weight: 900;
  font-size: 1.2rem;
}
.home-content {
  padding-top: 30px;
}
.user-profile {
  height: 200px;
}
.loginContainer .user-profile img {
  position: relative;
  left: 90px;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  background-color: #ffffff;
  border: 1px solid red;
}
.loginContainer .user-profile input {
  position: relative;
  top: -71px;
  left: -110px;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  /* background-color: transparent; */
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
#profile-progress {
  width: 130%;
  position: relative;
  top: -69px;
  left: -60px;
}

/* #profile-progress #progress-bar {
  width: 100%;
  position: relative;
  top: 22px;
} */
/* #profile-progress p {
  position: relative;
  text-align: center;
  top: -6px;
} */
.user-profile button {
  position: relative;
  bottom: 125px;
  left: 320px;
  width: 80px;
}
.profileContainer {
  margin-top: 75px !important;
  padding: 13px 60px !important;
}

.video-progress {
  position: relative;
  top: -16px;
}
.thumbnail-progress {
  position: relative;
  top: -16px;
}
#modal-thumbnail {
  height: 100px;
}
.text-success {
  display: flex;
  margin: auto;
}
.text-primary {
  display: flex;
  margin: auto;
}
hr {
  border-top: 1px solid rgba(255, 255, 255, 0.57) !important;
}

.main-carousel {
  cursor: pointer;
  width: 80%;
  margin: 0 auto;
}
.carousel {
  max-height: 70vh;
}
.img:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 30%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1));
  /* box-shadow: 15px 0px 100px 50px black inset; */
  /* background:rgba(0,0,0,0.6); */
  /* opacity: 1; */
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

@media only screen and (max-width: 522px) {
  #signUpName {
    width: 100%;
  }
  .main-carousel {
    margin-top: 50px;
    width: 100%;
  }
  .margin-top {
    margin-top: 50px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    top: 35px !important;
  }
}

input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}
