.category-list-title {
	text-align: center;
	/* margin: 8px 0 24px 0; */
}

#add-category-btn {
	position: absolute;
	text-align: center;
	top: 4px;
	right: 4px;
	width: 80px;
	padding: 8px;
	border-radius: 6px;
	background: #000;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
}

#add-category-btn:hover {
	background: #fff;
	color: #000;
	border: 0.5px solid #000;
}

#add-mult-category-btn {
	position: absolute;
	top: 54px;
	right: 4px;
	width: 80px;
	padding: 8px;
	border-radius: 6px;
	background: #000;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
}

#add-mult-category-btn:hover {
	background: #fff;
	color: #000;
	border: 0.5px solid #000;
}
