body {
  background: rgb(223, 222, 222);
}
#main-div {
  margin-top: 70px;
}
#main-content {
  display: flex;
  flex-direction: row;
}

#add-btn {
  position: absolute;
  border-radius: 8px;
  top: 8px;
  left: 8px;
  height: 50px;
  width: max-content;
  padding: 0px;
  color: white;
  background: rgb(103, 208, 226);
  border: none;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
}

#add-video-btn {
  position: absolute;
  border-radius: 8px;
  top: 8px;
  left: 15%;
  height: 50px;
  width: max-content;
  padding: 5px;
  color: white;
  background: rgb(103, 208, 226);
  border: none;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

#add-btn:hover,
#add-multiple-btn:hover,
#refresh-btn:hover,
#add-video-btn:hover {
  background: rgb(19, 188, 218);
}

#top-btn-div {
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  margin: 0 auto 0 auto;
}

#btn-cat {
  position: relative;
  left: 50%;
  text-align: center;
  width: 100px;
  cursor: pointer;
  background: rgb(238, 34, 211);
  color: #fff;
  border-radius: 6px;
  padding: 6px;
}

#btn-cat:hover {
  background: rgb(206, 19, 181);
}

#btn-audio {
  display: inline-block;
  width: 100px;
  cursor: pointer;
  background: rgb(238, 34, 211);
  color: #fff;
  border-radius: 6px;
  padding: 6px;
  margin-left: 2px;
}

#btn-audio:hover {
  background: rgb(206, 19, 181);
}

#btn-text {
  display: inline-block;
  width: 100px;
  cursor: pointer;
  background: rgb(238, 34, 211);
  color: #fff;
  border-radius: 6px;
  padding: 6px;
  margin-left: 2px;
}

#btn-text:hover {
  background: rgb(206, 19, 181);
}

#add-multiple-btn {
  position: absolute;
  border-radius: 8px;
  top: 8px;
  left: 66px;
  height: 50px;
  width: 50px;
  padding: 0px;
  color: white;
  background: rgb(103, 208, 226);
  border: none;
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}

#btn-signout {
  position: absolute;
  border-radius: 8px;
  top: 8px;
  right: 8px;
  height: 50px;
  width: 100px;
  padding: 0px;
  color: white;
  background: rgb(42, 27, 245);
  text-align: center;
}

#btn-signout:hover {
  background: rgb(30, 19, 182);
}

#refresh-btn {
  position: absolute;
  border-radius: 8px;
  top: 8px;
  left: 12%;
  height: 50px;
  width: 30px;
  padding: 0px;
  color: white;
  background: rgb(103, 208, 226);
  border: none;
  font-weight: 900;
  font-size: 20px;
  text-align: center;
}
